<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="checkPermissions(['ACCOUNTANT_INSERT']) ? ' ' : ''"
        >
          <template v-slot:toolbar>
            <div class="row">
              <router-link
                to="/accounting/accounts/add"
                v-if="checkPermissions(['ACCOUNTANT_INSERT'])"
              >
                <b-button size="sm" variant="primary">
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  &nbsp; Thêm tài khoản kế toán
                </b-button>
              </router-link>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <div class="row">
                  <b-input
                    placeholder="Tìm kiếm theo tên"
                    v-model="filter.name"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-2 btn-sm mr-2"
                    v-on:input="debounceInput"
                  ></b-input>
                  <b-input
                    placeholder="Tìm kiếm theo mã"
                    v-model="filter.code"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-2 btn-sm mr-2"
                    v-on:input="debounceInput"
                  ></b-input>
                </div>
              </b-form>
            </div>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="accountants"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span v-text="row.item.count" class="text-right"></span>
                </div>
              </template>
              <template v-slot:cell(id)="row">
                <span
                  style="cursor: pointer"
                  @click="createChildAccc(row.item)"
                  class="fa fa-plus-circle text-primary mr-1"
                  v-b-tooltip.hover
                  title="Thêm tài khoản con"
                ></span>
                <span v-text="row.item.id"></span>
              </template>
              <template v-slot:cell(name)="row">
                <p
                  class="mb-0"
                  v-bind:class="{
                    'ml-10': row.item.level == 1,
                    'ml-20': row.item.level == 2,
                    'ml-30': row.item.level == 3,
                    'ml-40': row.item.level == 4,
                    'ml-50': row.item.level == 5,
                  }"
                >
                  {{ row.item.name }}
                </p>
              </template>
              <template v-slot:cell(code)="row">
                <p
                  class="mb-0"
                  v-bind:class="{
                    'ml-10': row.item.level == 1,
                    'ml-20': row.item.level == 2,
                    'ml-30': row.item.level == 3,
                    'ml-40': row.item.level == 4,
                    'ml-50': row.item.level == 5,
                  }"
                >
                  {{ row.item.code }}
                </p>
              </template>
              <template v-slot:cell(status)="row">
                <i
                  v-bind:class="{
                    'fas fa-check text-success': row.item.status == 1,
                    'fas fa-minus-circle text-danger': row.item.status != 1,
                  }"
                />
              </template>
              <template
                v-slot:cell(actions)="row"
                v-if="
                  checkPermissions(['ACCOUNTANT_UPDATE', 'ACCOUNTANT_DELETE'])
                "
              >
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkPermissions(['ACCOUNTANT_UPDATE'])"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermissions(['ACCOUNTANT_DELETE'])"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số tài khoản:
                  {{ totalItems }}
                </p>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import { getToastInstance } from './../../../utils/toastHelper';

export default {
  data() {
    return {
      importing: false,
      accountants: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      jobName: '',
      storeName: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      filter: {
        name: '',
        code: '',
      },
      fileUpload: null,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '3%',
          },
          tdClass: 'sttClass',
          sortable: false,
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'code',
          label: 'Mã tài khoản',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên tài khoản',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'store',
          label: 'Cửa hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          formatter: (value) => {
            return value?.name;
          },
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          formatter: (value) => {
            if (value === 0) {
              return 'Mặc định';
            }
            if (value === 1) {
              return 'Tiền mặt';
            }
            if (value === 2) {
              return 'Chuyển khoản';
            }
            if (value === 3) {
              return 'Quẹt thẻ';
            }
            if (value === 4) {
              return 'Trả góp';
            }
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-center',
        },
        {
          key: 'creator',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          formatter: (value) => {
            return value.fullName;
          },
        },
        {
          key: checkPermissions(['ACCOUNTANT_UPDATE', 'ACCOUNTANT_DELETE'])
            ? 'actions'
            : '',
          label: '',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kế toán', route: 'accountant' },
      { title: 'Danh sách tài khoản' },
    ]);

    if (this.$route.params.createAccountantSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.createAccountantSuccess = false;
    }
    if (this.$route.params.updateAccountantSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.updateAccountantSuccess = false;
    }
  },
  created() {
    this.fetchAccountant();
  },
  methods: {
    ...getToastInstance(),
    createChildAccc: async function (item) {
      this.$router.push({
        name: 'add-accountant',
        query: { parentId: item.id },
      });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-accountant',
        query: { id: item.id },
      });
    },
    fetchAccountant: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      let params = {
        getFull: true,
        name: this.filter.name,
        code: this.filter.code,
      };

      ApiService.setHeader();
      ApiService.query('/accountants', { params }).then((response) => {
        this.accountants = response.data.data;
        this.totalItems = response.data.data.length;
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`accountants/${item.id}`)
        .then(() => {
          this.makeToastSuccess('Xoá tài khoản kế toán thành công!');
          this.fetchAccountant();
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailed(message);
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa tài khoản!',
        text: 'Bạn có chắc muốn xóa tài khoản này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkPermissions,
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailed(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    debounceInput: decounce(function () {
      this.fetchAccountant();
    }, 1000),
    selectFile: function () {
      document.getElementById('fileUpload').click();
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>
